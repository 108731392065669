@import "src/app/css/breakpoints";
@import "src/app/css/mixins";
@import "src/app/css/color-palette";

.main {
  background: $white;
  padding-top: 64px;
  @include sm {
    background: transparent;
  }
}