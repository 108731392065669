@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:wght@400;500;700&display=swap);
.loading {
    overflow: hidden;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.35);
    height: 100%;
    width: 100%;
}

.loading .anticon svg{
    width: 4rem;
    height: 4rem;
}

.styles_title_icon__2Y8MW{align-items:center;display:flex;flex-direction:column}.styles_title__2TrTa{margin-top:11px}

.styles_error_icon__3KpXM{font-size:30px;color:#FF1A2E}.styles_error_content__1Mzki{height:189px;align-items:center;display:flex;flex-direction:column;font-size:16px;font-family:Roboto,Arial,sans-serif;color:rgba(70,86,98,0.85098)}.styles_error_context__2xmcL{text-align:center}.styles_footer_error__2-aBk{margin-top:57px}@media (max-width: 575px){.styles_footer_error__2-aBk{margin-top:25px}}.styles_footer_error__2-aBk button{width:75px;background-color:#FF1A2E;font-weight:400;border-radius:4px}

.styles_progress_content__1NWPI{height:241px;align-items:center;display:flex;flex-direction:column;padding:31px}.styles_progress_title__2-eMe{font-size:16px;color:rgba(70,86,98,0.85098);font-family:Roboto,Arial,sans-serif;margin-top:15px}

.styles_search_input__XBhpI input,.styles_search_input__XBhpI span,.styles_search_input__XBhpI button{border-radius:4px}

.styles_error_icon__rtr-Z{font-size:30px;color:#FF1A2E}.styles_error_content__5uwlD{height:189px;align-items:center;display:flex;flex-direction:column;font-size:16px;font-family:Roboto,Arial,sans-serif;color:rgba(70,86,98,0.85098)}.styles_error_context__1HzZ8{text-align:center}.styles_footer_error__2mI3a{margin-top:57px}@media (max-width: 575px){.styles_footer_error__2mI3a{margin-top:25px}}.styles_footer_error__2mI3a button{width:75px;background-color:#FF1A2E;font-weight:400;border-radius:4px}

.styles_progress_content__ckcQ3{height:241px;align-items:center;display:flex;flex-direction:column;padding:31px}.styles_progress_title__3Qk61{font-size:16px;color:rgba(70,86,98,0.85098);font-family:Roboto,Arial,sans-serif;margin-top:15px}

.styles_planned_load__a73II{padding:10px 20px 20px;width:430px;margin-bottom:20px;font-family:Roboto,Arial,sans-serif;background-color:#FEFFE6;text-align:center;color:#614700}@media (max-width: 575px){.styles_planned_load__a73II{width:100%}}.styles_planned_load__title__3xT1A{font-size:16px}.styles_planned_load__text__22g__{font-size:14px}

.styles_history_step__1vKpl{min-width:430px;padding:20px 60px;background-color:#FAFAFA;border-radius:2px;margin-bottom:25px}.styles_history_step__1vKpl .ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after{margin-top:-6px;width:2px;height:15px;background-color:#E8E8E8}@media (max-width: 575px){.styles_history_step__1vKpl{min-width:100%;padding:20px 10px}}.styles_history_step__title__1gLfU{font-size:22px;font-family:Roboto,Arial,sans-serif;font-weight:500;color:rgba(0,0,0,0.85098)}.styles_history_step__steps__2ixIy .ant-steps-item-content{width:auto;min-height:auto}.styles_history_step__steps__2ixIy .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description{color:#465662;padding-bottom:7px}

.styles_table__3GXcn{padding-bottom:30px;width:100%}.styles_table__3GXcn .sort_prefix{display:none}@media (max-width: 575px){.styles_table__3GXcn{padding-left:10px;padding-right:10px}.styles_table__3GXcn .sort_prefix{display:inline}.styles_table__3GXcn .ant-table-column-sorters{padding:0;display:flex;justify-content:space-between}.styles_table__3GXcn .ant-pagination-total-text{text-align:center;width:100%;margin-right:0}.styles_table__3GXcn .ant-pagination-item{margin-right:2px}.styles_table__3GXcn .ant-table-thead>tr>th,.styles_table__3GXcn .ant-table-tbody>tr>td,.styles_table__3GXcn .ant-table tfoot>tr>th,.styles_table__3GXcn .ant-table tfoot>tr>td{padding:5px;font-size:12px}}

.styles_result_block__title__JX1Wa{padding-bottom:15px;color:#8C8C8C;font-family:Roboto,Arial,sans-serif;font-size:14px;font-weight:400}.styles_refresh_button__1OBsA{margin-bottom:15px;width:100%}@media (max-width: 575px){.styles_refresh_button__1OBsA{margin-left:15px}}.styles_close_button_container__1rZL0{width:100%;display:flex;justify-content:center}.styles_close_button_container__1rZL0 button{font-size:16px;font-family:Roboto,Arial,sans-serif;font-weight:400;width:75px;border-radius:4px}.styles_close_button_container__1rZL0 button span{line-height:1;display:flex;align-items:center;justify-content:center}

.styles_result_block__29a-i{padding:25px 50px;margin-top:40px;margin-bottom:40px;width:1136px;background-color:#fff;border-radius:2px;display:flex;flex-direction:column;align-items:center;z-index:1;overflow:hidden}@media (max-width: 1199px){.styles_result_block__29a-i{width:90%}}@media (max-width: 575px){.styles_result_block__29a-i{width:100%;margin-bottom:0;padding:25px 0}}.styles_load_enter__2dxDV{opacity:0;transform:translateY(-75px)}.styles_load_enter_active__10THY{opacity:1;transition:.6s;transform:translateY(0)}.styles_load_exit__I0FWA{opacity:1;transform:translateY(0)}.styles_load_exit_active__2BSb5{opacity:0;transform:translateY(-75px);transition:.3s}.styles_load_exit_done__P0oAV{opacity:0}

.loads_page__21vRW{background:linear-gradient(180deg, #fdfdfd 0%, #e2eef6 100%);display:flex;flex-direction:column;align-items:center;padding-top:69px;width:100%;min-height:calc(100vh - 64px)}.loads_page__21vRW .loads_container__1FBT_{width:552px;text-align:center;font-family:'Open Sans', sans-serif}@media (max-width: 575px){.loads_page__21vRW .loads_container__1FBT_{width:100%;position:relative}}.loads_page__21vRW .loads_container__1FBT_ .loads_title__3B-Or{font-size:32px;font-weight:600;letter-spacing:0;color:#465662;opacity:1}@media (max-width: 575px){.loads_page__21vRW .loads_container__content__3lhs2{margin:auto;max-width:250px}}.loads_page__21vRW .loads_container__1FBT_ .loads_subTitle__2oO9P{font-size:16px;font-weight:400;letter-spacing:0;color:#465662;opacity:1}.loads_page__21vRW .loads_container__1FBT_ .loads_inputMargin__1DFZx{margin-top:30px;display:flex;justify-content:center;position:relative;z-index:1}.loads_page__21vRW .loads_container__1FBT_ .loads_inputMargin__1DFZx>div{width:100%}@media (max-width: 767px){.loads_page__21vRW .loads_container__1FBT_ .loads_inputMargin__1DFZx{margin:30px auto 0 auto;max-width:95%}}.loads_page__21vRW .loads_container__1FBT_ .loads_bgImage__20vD8{position:fixed;top:43%;left:0;overflow:hidden;width:100%;height:360px;background:url(/static/media/graph.4c6b328c.png) no-repeat;background-size:cover}@media (max-width: 575px){.loads_page__21vRW .loads_container__1FBT_ .loads_bgImage__20vD8{top:400px;height:100px;background-position:center}}

.main_main__2eRbl{background:#fff;padding-top:64px}@media (max-width: 575px){.main_main__2eRbl{background:transparent}}

input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}body{background:linear-gradient(180deg, #fdfdfd 0%, #e2eef6 100%)}@media (max-width: 575px){body{overflow:hidden;overflow-y:scroll !important;background:linear-gradient(180deg, #fdfdfd 0%, #e2eef6 100%)}body .ant-layout{background:transparent}}.ant-modal-mask{background-color:transparent}.ant-modal{top:340px;border-radius:2px}.ant-tag-blue{color:#465662}.ant-modal-content{box-shadow:none}.custom-modal{width:1136px}@media (max-width: 1199px){.custom-modal{width:90%}}@media (max-width: 991px){.custom-modal{width:730px}}@media (max-width: 767px){.custom-modal{width:90%}}@media (max-width: 575px){.custom-modal{width:100%}}@media (max-width: 575px){.custom-modal-wrap{position:relative;overflow:hidden;top:15px}.custom-modal-wrap .ant-modal{position:static;position:initial;margin-bottom:0;padding-bottom:0}}

