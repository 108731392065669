@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:wght@400;500;700&display=swap');
@import "src/app/css/breakpoints";
@import "src/app/css/mixins";
@import "./color-palette";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number]{
  -moz-appearance: textfield;
}

body {
  background: linear-gradient(180deg, rgba(253,253,253,1) 0%, rgba(226,238,246,1) 100%);
  @include sm {
    overflow: hidden;
    overflow-y: scroll !important;
    background: linear-gradient(180deg, #fdfdfd 0%, #e2eef6 100%);
    .ant-layout {
      background: transparent;
    }
  }
}

.ant-modal-mask{
  background-color: transparent;
}

.ant-modal{
  top: 340px;
  border-radius: 2px;
}

.ant-tag-blue{
  color: $san_juan;
}

.ant-modal-content{
  box-shadow: none;
}

.custom-modal{
  width: 1136px;

  @include xl {
    width: 90%;
  }

  @include lg {
    width: 730px;
  }

  @include md {
    width: 90%;
  }

  @include sm {
    width: 100%;
  }
}

.custom-modal-wrap {
  @include sm {
    position: relative;
    overflow: hidden;
    top: 15px;
    .ant-modal {
      position: initial;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

