@import "src/app/css/color-palette";
@import "src/app/css/breakpoints";
@import "src/app/css/mixins";

.page {
  background: linear-gradient(180deg, rgba(253,253,253,1) 0%, rgba(226,238,246,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 69px;
  width: 100%;
  min-height: calc(100vh - 64px);

  .container{
    width: 552px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;

    @include sm {
      width: 100%;
      position: relative;
    }

    .title{
      font-size: 32px;
      font-weight: 600;
      letter-spacing: 0;
      color: $san_juan;
      opacity: 1;
    }

    &__content {
      @include sm {
        margin: auto;
        max-width: 250px;
      }
    }

    .subTitle{
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0;
      color: $san_juan;
      opacity: 1;
    }

    .inputMargin{
      margin-top: 30px;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;

      > div {
        width: 100%;
      }

      @include md {
        margin: 30px auto 0 auto;
        max-width: 95%;
      }
    }

    .bgImage {
      position: fixed;
      top: 43%;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 360px;
      background: url("../../imges/graph.png") no-repeat;
      background-size: cover;
      @include sm {
        top: 400px;
        height: 100px;
        background-position: center;
      }
    }
  }
}