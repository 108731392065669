
$zircon: #F5F6F6;
$shamrock: #2BC97C;

$pattens_blue: #E2EEF6;
$pacific_blue: #039BE6;
$alice_blue: #E6F7FF;

$san_juan: #465662;
$san_juan_dark: #465662D9;

$white: #FFFFFF;
$dark_white: #FDFDFD;
$navy_white: #0015291F;
$white_smoke: #F5F5F5;

$gainsboro: #D9D9D9;
$gainsboro_white: #E8E8E8;

$black: #000000;
$black_less: #000000D9;

$columbia_blue: #91D5FF;

$suva_grey: #8C8C8C;
$lime_green: #36C626;
$black_smoke: #000000A6;

$torch_red: #FF1A2E;

$gray_bg: #FAFAFA;

$light_yellow: #FEFFE6;
$brown: #614700
