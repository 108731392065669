@import "src/app/css/variables";
@import "src/app/css/color-palette";
@import "src/app/css/breakpoints";
@import "src/app/css/mixins";

.result_block {
  padding: 25px 50px;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 1136px;
  background-color: $white;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  overflow: hidden;

  @include xl {
    width: 90%;
  }

  @include sm {
    width: 100%;
    margin-bottom: 0;
    padding: 25px 0;
  }
}

//CSSTransition animation
.load_enter {
  opacity: 0;
  transform: translateY(-75px);
}

.load_enter_active {
  opacity: 1;
  transition: .6s;
  transform: translateY(0);
}

.load_exit {
  opacity: 1;
  transform: translateY(0);
}

.load_exit_active {
  opacity: 0;
  transform: translateY(-75px);
  transition: .3s;
}

.load_exit_done {
  opacity: 0;
}
